<template>
  <main class="price-list bg-gray-50">
    <div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:flex-col sm:align-center">
        <h1 class="text-5xl font-extrabold text-gray-900 text-center">
          Cennik
        </h1>
        <p class="mt-5 text-xl text-gray-500 text-center">
          Ceny dla klienta indywidualnego.
        </p>
      </div>
      <div class="flex flex-wrap justify-center w-full mt-12 space-y-4">
        <div
          v-for="tier in tiers"
          :key="tier.name"
          class="w-4/5 border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200 bg-white"
        >
          <div
            class="flex flex-wrap justify-between items-center px-4 sm:px-6 py-4"
          >
            <h2
              class="w-full sm:w-auto text-lg leading-6 font-medium text-gray-900"
            >
              {{ tier.name }}
            </h2>
            <p class="mt-2">
              <span class="text-lg font-extrabold text-gray-900"
                >{{ tier.priceMonthly }} {{ tier.pricePln }}</span
              >
              <span class="text-sm font-medium text-gray-500">
                {{ tier.description }}</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
const tiers = [
  {
    name: "Alba",
    priceMonthly: "36.00",
    pricePln: "zł",
  },
  {
    name: "Apretura",
    priceMonthly: "10.90",
    pricePln: "zł",
  },
  {
    name: "Bermudy",
    priceMonthly: "20.90",
    pricePln: "zł",
  },
  {
    name: "Bezrękawnik ocieplany",
    priceMonthly: "25.90",
    pricePln: "zł",
  },
  {
    name: "Bolerko",
    priceMonthly: "20.90",
    pricePln: "zł",
  },
  {
    name: "Bieżnik",
    priceMonthly: "20.90",
    pricePln: "zł",
  },
  {
    name: "Bluza",
    priceMonthly: "24.90",
    pricePln: "zł",
  },
  {
    name: "Bluzka",
    priceMonthly: "14.90",
    pricePln: "zł",
  },
  {
    name: "Chusta",
    priceMonthly: "20.00",
    pricePln: "zł",
  },
  {
    name: "Czapka",
    priceMonthly: "20.00",
    pricePln: "zł",
  },
  {
    name: "Dywanik mały",
    priceMonthly: "29.90",
    pricePln: "zł",
    description: " /m2",
  },
  {
    name: "Dywanik duży",
    priceMonthly: "29.90",
    pricePln: "zł",
    description: " /m2",
  },

  {
    name: "Dres II części",
    priceMonthly: "46.90",
    pricePln: "zł",
  },
  {
    name: "Firanka",
    priceMonthly: "od 40.00",
    pricePln: "zł",
  },
  {
    name: "Futro naturalne krótkie",
    priceMonthly: "-",
  },
  {
    name: "Futro sztuczne k/dy",
    priceMonthly: "45.90/ 60.90",
    pricePln: "zł",
  },
  {
    name: "Futrzak barani",
    priceMonthly: "od 50.00",
    pricePln: "zł",
  },
  {
    name: "Garnitur 2-częściowy",
    priceMonthly: "55.90",
    pricePln: "zł",
  },
  {
    name: "Garnitur 3-częściowy",
    priceMonthly: "67.90",
    pricePln: "zł",
  },
  {
    name: "Garnitur dziecinny",
    priceMonthly: "50.90",
    pricePln: "zł",
  },
  {
    name: "Impregnacja",
    priceMonthly: "20.90",
    pricePln: "zł",
  },
  {
    name: "Kamizelka/ ocieplana",
    priceMonthly: "29.90",
    pricePln: "zł",
  },
  {
    name: "Kamizelka puchowa",
    priceMonthly: "49.90",
    pricePln: "zł",
  },
  {
    name: "Koszulka polo",
    priceMonthly: "14.50",
    pricePln: "zł",
  },
  {
    name: "Kitel",
    priceMonthly: "15.90",
    pricePln: "zł",
  },
  {
    name: "Koc",
    priceMonthly: "50.00",
    pricePln: "zł",
  },
  {
    name: "Koc duży",
    priceMonthly: "75.00",
    pricePln: "zł",
  },

  {
    name: "Kocyk mały",
    priceMonthly: "45.00",
    pricePln: "zł",
  },
  {
    name: "Kołdra",
    priceMonthly: "60.90",
    pricePln: "zł",
  },
  {
    name: "Kołdra puchowa",
    priceMonthly: "85.00",
    pricePln: "zł",
  },
  {
    name: "Kołdra/owcza wełna M/D",
    priceMonthly: "60.90/ 80.90",
    pricePln: "zł",
  },
  {
    name: "Kombinezon narciarski",
    priceMonthly: "60.90",
    pricePln: "zł",
  },
  {
    name: "kombinezon narciarski-dziecięcy",
    priceMonthly: "40.90/ 55.90",
    pricePln: "zł",
  },
  {
    name: "Kombinezon roboczy",
    priceMonthly: "36.90",
    pricePln: "zł",
  },
  {
    name: "Koszula",
    priceMonthly: "13.90",
    pricePln: "zł",
  },
  {
    name: "Koszula-prasowanie",
    priceMonthly: "11.90",
    pricePln: "zł",
  },
  {
    name: "Kożuch ekologiczny K/D",
    priceMonthly: "50.90/ 69.90",
    pricePln: "zł",
  },
  {
    name: "Kożuch zamszowy K/D",
    priceMonthly: "-",
  },
  {
    name: "Krawat",
    priceMonthly: "20.90",
    pricePln: "zł",
  },

  {
    name: "Kurtka",
    priceMonthly: "45.00",
    pricePln: "zł",
  },
  {
    name: "Kurtka dziecinna",
    priceMonthly: "40.90",
    pricePln: "zł",
  },
  {
    name: "Kurtka ocieplana",
    priceMonthly: "50.90",
    pricePln: "zł",
  },
  {
    name: "Kołdra puchowa",
    priceMonthly: "85.00",
    pricePln: "zł",
  },
  {
    name: "Kurtka puchowa",
    priceMonthly: "65.90",
    pricePln: "zł",
  },
  {
    name: "Kurtka skórzana",
    priceMonthly: "-",
  },
  {
    name: "Kurtka zamszowa",
    priceMonthly: "-",
  },
  {
    name: "Kurtka na motor",
    priceMonthly: "50.00",
    pricePln: "zł",
  },
  {
    name: "Marynarka",
    priceMonthly: "26.90",
    pricePln: "zł",
  },
  {
    name: "Marynarka skórzana",
    priceMonthly: "-",
  },
  {
    name: "Narzuta duża",
    priceMonthly: "60.00",
    pricePln: "zł",
  },
  {
    name: "Narzuta średnia",
    priceMonthly: "45.00",
    pricePln: "zł",
  },

  {
    name: "Obrus duży",
    priceMonthly: "22.00",
    pricePln: "zł",
  },
  {
    name: "Płaszcz/ Jesionka",
    priceMonthly: "58.90",
    pricePln: "zł",
  },
  {
    name: "Prochowiec",
    priceMonthly: "49.90",
    pricePln: "zł",
  },
  {
    name: "Płaszcz puchowy",
    priceMonthly: "79.90",
    pricePln: "zł",
  },
  {
    name: "Płaszcz skórzany",
    priceMonthly: "-",
  },
  {
    name: "Poduszka(jaś) puchowa",
    priceMonthly: "35.90",
    pricePln: "zł",
  },
  {
    name: "Poduszka duża",
    priceMonthly: "45.90",
    pricePln: "zł",
  },
  {
    name: "Poduszka mała (jaś)",
    priceMonthly: "19.90",
    pricePln: "zł",
  },
  {
    name: "Poduszka średnia",
    priceMonthly: "28.90",
    pricePln: "zł",
  },
  {
    name: "Pokrowce - samochod",
    priceMonthly: "100.90",
    pricePln: "zł",
  },
  {
    name: "Pokrowiec fotel",
    priceMonthly: "45.90",
    pricePln: "zł",
  },
  {
    name: "Pokrowiec sofa",
    priceMonthly: "100.00",
    pricePln: "zł",
  },

  {
    name: "Pokrowiec krzesło",
    priceMonthly: "29.90",
    pricePln: "zł",
  },
  {
    name: "Pokrowiec na materac M/D",
    priceMonthly: "85.90/ 110.00",
    pricePln: "zł",
  },
  {
    name: "Ponczo",
    priceMonthly: "40.00",
    pricePln: "zł",
  },
  {
    name: "Rękawiczki skórzane",
    priceMonthly: "-",
  },
  {
    name: "Spodnie",
    priceMonthly: "25.90",
    pricePln: "zł",
  },
  {
    name: "Spdnie narciarskie",
    priceMonthly: "40.90",
    pricePln: "zł",
  },
  {
    name: "Spodnie skórzane",
    priceMonthly: "-",
  },
  {
    name: "Spódnica",
    priceMonthly: "25.00/ 35.00",
    pricePln: "zł",
  },
  {
    name: "Spódnica plisowana",
    priceMonthly: "37.90",
    pricePln: "zł",
  },
  {
    name: "Spódnica skórzana",
    priceMonthly: "-",
  },
  {
    name: "Spódnica wieczorowa",
    priceMonthly: "39.90",
    pricePln: "zł",
  },

  {
    name: "Sukienka",
    priceMonthly: "35.90",
    pricePln: "zł",
  },
  {
    name: "Sukienka dziecięca",
    priceMonthly: "25.90",
    pricePln: "zł",
  },
  {
    name: "Sukienka komunijna",
    priceMonthly: "50.00",
    pricePln: "zł",
  },
  {
    name: "Sukienka wieczorowa-duża",
    priceMonthly: "90.00",
    pricePln: "zł",
  },
  {
    name: "Sukienka wieczorowa - mała",
    priceMonthly: "60.00",
    pricePln: "zł",
  },
  {
    name: "Suknia ślubna",
    priceMonthly: "od 250",
    pricePln: "zł",
  },
  {
    name: "Sutanna",
    priceMonthly: "40.90",
    pricePln: "zł",
  },
  {
    name: "Sweter",
    priceMonthly: "23.00/ 30.90",
    pricePln: "zł",
  },
  {
    name: "Szal",
    priceMonthly: "20.90",
    pricePln: "zł",
  },
  {
    name: "Szlafrok M/D",
    priceMonthly: "28.90/ 35.90",
    pricePln: "zł",
  },
  {
    name: "Szorty",
    priceMonthly: "20.90",
    pricePln: "zł",
  },
  {
    name: "Śpiwór",
    priceMonthly: "60.90",
    pricePln: "zł",
  },
  {
    name: "Śpiwór puchowy",
    priceMonthly: "80.90",
    pricePln: "zł",
  },

  {
    name: "Toga",
    priceMonthly: "40.90",
    pricePln: "zł",
  },
  {
    name: "T-shirt",
    priceMonthly: "12.00",
    pricePln: "zł",
  },
  {
    name: "Tunika",
    priceMonthly: "20.90",
    pricePln: "zł",
  },
  {
    name: "Włochacz",
    priceMonthly: "60.00",
    pricePln: "zł",
  },
  {
    name: "Zabawka-pluszak",
    priceMonthly: "30.00/ 100.00",
    pricePln: "zł",
  },
  {
    name: "Zasłony małe 2,80x1,45",
    priceMonthly: "35.00/ 40.00",
    pricePln: "zł",
  },
  {
    name: "Zasłony duże",
    priceMonthly: "50.00/ 60.00",
    pricePln: "zł",
  },
  {
    name: "Żakiet",
    priceMonthly: "26.90",
    pricePln: "zł",
  },
  {
    name: "Magiel",
    priceMonthly: "13,90",
    pricePln: "zł",
    description: " /kg",
  },
  {
    name: "Magiel + krochmal",
    priceMonthly: "16.90",
    pricePln: "zł",
    description: " /kg",
  },
  {
    name: "Prasowanie",
    priceMonthly: "80% ceny",
  },
  {
    name: "Pranie + magiel + krochmal",
    priceMonthly: "19.00",
    pricePln: "zł",
    description: " /kg",
    services: true,
  },
];

export default {
  name: "PriceList",

  setup() {
    return {
      tiers,
    };
  },
};
</script>
